import React, { useState } from "react";
import axios from "axios";
import { PlusIcon } from "@heroicons/react/20/solid";

const GenerateCodesButton = () => {
  const [referralCodes, setReferralCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState("");
  const authToken = localStorage.getItem("token");

  const generateCodes = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://mosaic.web3talk.xyz/admin/admin-referrals",
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setReferralCodes(response.data.referralCodes);
    } catch (error) {
      console.error("Error generating codes:", error);
      // Replace alert with a notification method if desired
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    const message =
      "Here are some codes to invite your friends and family!\nOur website is www.meetmosaic.co\n\n";
    const formattedCodes = referralCodes.map((code) => code.code).join("\n");
    const textToCopy = message + formattedCodes;

    navigator.clipboard.writeText(textToCopy).then(
      () => {
        showNotification("Copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => {
      setNotification("");
    }, 3000); // Change the duration as needed
  };

  return (
    <div className="text-center">
      <button
        onClick={generateCodes}
        disabled={loading}
        className="mt-5 mb-12 inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium leading-5 text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        {loading ? (
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12c0-4.418 3.582-8 8-8 1.044 0 2.04.202 2.95.566l1.5-1.5A9.97 9.97 0 0012 2C6.477 2 2 6.477 2 12c0 1.657.403 3.208 1.1 4.563l1.5-1.5A7.987 7.987 0 014 12z"
            ></path>
          </svg>
        ) : (
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        )}
        Generate 10 Codes
      </button>
      {referralCodes.length > 0 && (
        <>
          <div className="mt-8">
            <h3 className="text-lg font-semibold text-white mb-8">
              Referral Codes:
            </h3>
            <div className="mt-2">
              {referralCodes.map((code) => (
                <span
                  key={code._id}
                  className="inline-flex items-center justify-center rounded-full bg-gray-100 px-3 py-1 text-sm font-medium text-gray-800 mr-2 mb-12"
                >
                  {code.code}
                </span>
              ))}
            </div>
          </div>
          <button
            onClick={copyToClipboard}
            className="mt-2 mb-2 inline-flex items-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Copy Codes to Clipboard
          </button>
        </>
      )}
      {notification && (
        <div className="mt-4 text-sm text-green-600">{notification}</div>
      )}
    </div>
  );
};

export default GenerateCodesButton;
