import React, { useState } from "react";
import axios from "axios";
import Notification from "./Notification"; // Adjust the import path as necessary

const BlogForm = () => {
  // State for form fields
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [articleUrl, setArticleUrl] = useState("");
  const [tags, setTags] = useState("");
  const [error, setError] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");

  // Form submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    // Split tags by comma and remove whitespace
    const tagsArray = tags.split(",").map((tag) => tag.trim());

    // Construct the blog data
    const blogData = {
      title,
      imageUrl,
      articleUrl,
      tags: tagsArray,
    };

    try {
      // Retrieve the token from local storage
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Authentication token not found.");
        return;
      }

      // Make the POST request to the API endpoint
      const response = await axios.post("https://mosaic.web3talk.xyz/admin/blog", blogData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Set notification for success response
      setNotificationTitle("Blog Created");
      setNotificationMessage("Your blog has been successfully created.");
      setShowNotification(true);

      // Clear the form fields
      setTitle("");
      setImageUrl("");
      setArticleUrl("");
      setTags("");
    } catch (err) {
      // Set notification for error response
      const errorMessage =
        err.response?.data?.error || err.message || "Failed to create blog.";
      setNotificationTitle("Error Creating Blog");
      setNotificationMessage(errorMessage);
      setShowNotification(true);

      // Log error to console and set error message
      console.error("Error creating blog:", err);
      setError(errorMessage);
    }
  };

  return (
    <div className="max-w-md mx-auto text-white">
      <h2 className="text-lg font-semibold">Create New Blog</h2>
      <form onSubmit={handleSubmit} className="mt-4 space-y-4">
        {/* Title field */}
        <div>
          <label htmlFor="title" className="block text-sm font-medium">
            Title
          </label>
          <input
            id="title"
            type="text"
            required
            className="mt-1 block w-full rounded-md bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        {/* Image URL field */}
        <div>
          <label htmlFor="imageUrl" className="block text-sm font-medium">
            Image URL
          </label>
          <input
            id="imageUrl"
            type="url"
            required
            className="mt-1 block w-full rounded-md bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
          />
        </div>

        {/* Article URL field */}
        <div>
          <label htmlFor="articleUrl" className="block text-sm font-medium">
            Article URL
          </label>
          <input
            id="articleUrl"
            type="url"
            required
            className="mt-1 block w-full rounded-md bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={articleUrl}
            onChange={(e) => setArticleUrl(e.target.value)}
          />
        </div>

        {/* Tags field */}
        <div>
          <label htmlFor="tags" className="block text-sm font-medium">
            Tags (comma separated)
          </label>
          <input
            id="tags"
            type="text"
            className="mt-1 block w-full rounded-md bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
          />
        </div>

        {/* Error message */}
        {error && <p className="mt-2 text-sm text-red-500">{error}</p>}

        {/* Submit button */}
        <div>
          <button
            type="submit"
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Create Blog
          </button>
        </div>
      </form>
      {showNotification && (
        <Notification
          show={!!showNotification}
          setShow={setShowNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      )}
    </div>
  );
};

export default BlogForm;
