import React from "react";
import axios from "axios";

const UserDetailModal = ({ user, onClose, onUserUpdate }) => {
  if (!user) return null;

  const authToken = localStorage.getItem("token"); // Assuming the token is stored in localStorage

  const handleApprove = async () => {
    // Confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to approve this user?"
    );
    if (isConfirmed) {
      try {
        const response = await axios.post(
          `https://mosaic.web3talk.xyz/admin/approve/${user._id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        alert("User approved successfully.");
        //onUserUpdate(); // Trigger a refresh or update of the user's status
        onClose(); // Close the modal
      } catch (error) {
        console.error("Error approving user:", error);
        alert(
          "Error approving user. Please check the console for more information."
        );
      }
    }
  };

  const viewOnStripe = () => {
    const stripeUrl = `https://dashboard.stripe.com/customers/${user.stripeCustomerId}`;
    window.open(stripeUrl, "_blank");
  };

  const renderDetails = (data, prefix = "") => {
    return Object.entries(data).map(([key, value]) => {
      // Check for null or undefined values
      if (value === null || value === undefined) {
        return renderPlaceholder(key);
      }
      // Check if the value is an array and render its items
      else if (Array.isArray(value)) {
        return (
          <div key={key} className="mb-4">
            <div className="font-bold text-gray-400 capitalize">
              {prefix}
              {key}:
            </div>
            {value.map((item, index) => (
              <div
                key={`${key}-${index}`}
                className="bg-gray-700 p-3 rounded-lg mt-2"
              >
                <RenderObjectDetails object={item} />
              </div>
            ))}
          </div>
        );
      }
      // Check if the value is an object and render its properties
      else if (typeof value === "object") {
        return (
          <div key={key} className="mb-4">
            <div className="font-bold text-gray-400 capitalize">
              {prefix}
              {key}:
            </div>
            <div className="bg-gray-700 p-3 rounded-lg">
              <RenderObjectDetails object={value} />
            </div>
          </div>
        );
      }
      // For all other types, render them as strings
      else {
        return renderStringValue(prefix, key, value);
      }
    });
  };

  // Render placeholder for null or undefined values
  const renderPlaceholder = (key) => (
    <div key={key} className="mb-2">
      <span className="font-bold text-gray-400 capitalize">{key}: </span>
      <span className="text-gray-200">N/A</span>
    </div>
  );

  // Render the properties of an object
  const RenderObjectDetails = ({ object }) => {
    return (
      <div className="bg-gray-700 p-3 rounded-lg">
        {Object.entries(object).map(([key, value]) => {
          // Convert non-string values to a string, format dates, or show 'N/A' for null/undefined
          let displayValue;
          if (value === null || value === undefined) {
            displayValue = "N/A";
          } else if (
            typeof value === "string" &&
            new Date(value).toString() !== "Invalid Date"
          ) {
            displayValue = new Date(value).toLocaleString();
          } else if (typeof value === "object") {
            // If it's a nested object, call this function recursively
            displayValue = <RenderObjectDetails object={value} />;
          } else {
            displayValue = value.toString();
          }

          return (
            <div key={key} className="mb-1">
              <span className="font-bold text-gray-400 capitalize">
                {key}:{" "}
              </span>
              <span className="text-gray-200">{displayValue}</span>
            </div>
          );
        })}
      </div>
    );
  };

  // Render string values
  const renderStringValue = (prefix, key, value) => {
    let displayValue =
      typeof value === "string" && new Date(value).toString() !== "Invalid Date"
        ? new Date(value).toLocaleString()
        : value.toString();
    return (
      <div key={`${prefix}${key}`} className="mb-2">
        <span className="font-bold text-gray-400 capitalize">
          {`${prefix}${key}`}:{" "}
        </span>
        <span className="text-gray-200">{displayValue}</span>
      </div>
    );
  };

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex">
      <div className="relative p-8 bg-gray-800 w-full max-w-lg m-auto flex-col flex rounded-lg shadow-lg">
        <div className="flex justify-between items-center pb-3">
          <p className="text-2xl font-bold text-white">User Details</p>
          <button onClick={onClose} className="modal-close cursor-pointer z-50">
            <svg
              className="fill-current text-white"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M12.45 11.414l5.536-5.536-1.036-1.036-5.536 5.536-5.536-5.536-1.036 1.036 5.536 5.536-5.536 5.536 1.036 1.036 5.536-5.536 5.536 5.536 1.036-1.036-5.536-5.536z" />
            </svg>
          </button>
        </div>
        <div className="text-white overflow-auto max-h-96">
          {renderDetails(user)}
        </div>
        <div className="flex justify-end pt-2">
          {user.application && !user.isApproved && (
            <button
              onClick={handleApprove}
              className="px-4 bg-green-600 p-3 rounded-lg text-white hover:bg-green-500 mr-2"
            >
              Approve
            </button>
          )}

          {user.stripeCustomerId && (
            <button
              onClick={viewOnStripe}
              className="px-4 bg-blue-600 p-3 rounded-lg text-white hover:bg-blue-500 mr-2"
            >
              View on Stripe
            </button>
          )}
          <button
            onClick={onClose}
            className="px-4 bg-gray-700 p-3 rounded-lg text-white hover:bg-gray-600"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserDetailModal;
