import React, { useState } from "react";
import Events from "../events/EventForms";
import BlogPosts from "../blogs/BlogPosts";
import Users from "../users/Users";
// import Approvals from "./Approvals";
import ReferralCodes from "./ReferralCodes";
import SubscriptionsList from "./GetSubscriptions";
// import Applications from "./Applications";

// Tab data
const tabs = [
  { name: "Events", href: "#", component: Events },
  { name: "Blog Posts", href: "#", component: BlogPosts },
  { name: "Users", href: "#", component: Users, current: true },
  //   { name: "Approvals", href: "#", component: Approvals },
  { name: "Refferal Codes", href: "#", component: ReferralCodes },
  { name: "Shipping Report", href: "#", component: SubscriptionsList },
  //   { name: "Applications", href: "#", component: Applications },
];

// Function to determine CSS classes based on active state
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SectionHeadings() {
  const [currentTab, setCurrentTab] = useState(
    tabs.find((tab) => tab.current).name
  );

  // Get the current tab's component
  const CurrentTabComponent = tabs.find(
    (tab) => tab.name === currentTab
  ).component;

  return (
    <div className="relative border-b border-gray-200 pb-5 sm:pb-0">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex items-center">
          <img
            src="https://web3talk-images.s3.amazonaws.com/img/logo.svg"
            alt="Logo"
            className="h-8 w-auto mr-2"
          />
          <h3 className="text-lg leading-6 font-medium text-white dark:text-white">
            - Admin Panel
          </h3>
        </div>
        <div className="mt-3 flex md:mt-0 md:absolute md:right-0 md:top-3"></div>
      </div>
      <div className="mt-4">
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => setCurrentTab(tab.name)}
                className={classNames(
                  currentTab === tab.name
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={currentTab === tab.name ? "page" : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div className="tab-content mt-4">
        {/* Render the current tab component */}
        <CurrentTabComponent />
      </div>
    </div>
  );
}
