import React, { useState, useEffect } from "react";
import axios from "axios";
import UserDetailModal from "./UserDetailModal";

const UsersTable = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const authToken = localStorage.getItem("token");

  const fetchUsers = async (query = "") => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://mosaic.web3talk.xyz/admin/users${query}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      localStorage.clear();
      window.location.reload();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  const closeModal = () => {
    setSelectedUser(null);
  };

  const renderUserRow = (user) => (
    <tr key={user._id} className="border-b border-gray-600">
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-300">
        {user.email}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400">
        {user.hasReferralCode ? "Yes" : "No"}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400">
        {user.application ? "Yes" : "No"}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
        <button
          onClick={() => handleUserClick(user)}
          className="text-indigo-400 hover:text-indigo-600"
        >
          Details
        </button>
      </td>
    </tr>
  );

  return (
    <div className="bg-gray-800 text-white">
      <div className="flex justify-between mb-4">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => fetchUsers()}
        >
          All Users
        </button>
        <button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => fetchUsers("?noReferralCode=true")}
        >
          Users without Referral Code
        </button>
        <button
          className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => fetchUsers("?hasReferralCode=true")}
        >
          Users with Referral Code (Unapproved)
        </button>
        <button
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => fetchUsers("?withStripeCustomer=true")}
        >
          Users with Stripe Customer ID
        </button>
        {/* New Button for Approved Users w/o Membership */}
        <button
          className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => fetchUsers("?withoutStripeID=true")}
        >
          Approved Users w/o Membership
        </button>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <table className="min-w-full divide-y divide-gray-600">
          <thead className="bg-gray-700">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
              >
                Email
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
              >
                Has Referral Code
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
              >
                Application
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-gray-800 divide-y divide-gray-600">
            {users.map(renderUserRow)}
          </tbody>
        </table>
      )}

      {selectedUser && (
        <UserDetailModal user={selectedUser} onClose={closeModal} />
      )}
    </div>
  );
};

export default UsersTable;
