import React from "react";
import SectionHeadings from "./components/welcome/sectionHeading";

const Welcome = () => {
  return (
    <div className="container mx-auto mt-10">
      <SectionHeadings />
    </div>
  );
};

export default Welcome;
