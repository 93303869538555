import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";

const SubscriptionsList = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const authToken = localStorage.getItem("token");

  useEffect(() => {
    const fetchSubscriptions = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "https://mosaic.web3talk.xyz/admin/subscriptions",
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        setSubscriptions(response.data.subscriptions);
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
        setError("Failed to fetch subscriptions.");
      } finally {
        setLoading(false);
      }
    };
    fetchSubscriptions();
  }, [authToken]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const downloadAsExcel = () => {
    const data = subscriptions.map((subscription) => ({
      "Customer Name": subscription.customer.name,
      "Customer Email": subscription.customer.email,
      "Shipping Name": subscription.customer.shipping?.name || "",
      "Shipping Address": subscription.customer.shipping?.address?.line1 || "",
      "Shipping City": subscription.customer.shipping?.address?.city || "",
      "Shipping State": subscription.customer.shipping?.address?.state || "",
      "Shipping Postal Code":
        subscription.customer.shipping?.address?.postal_code || "",
      "Shipping Country":
        subscription.customer.shipping?.address?.country || "",
      Status: subscription.status,
      "Subscription Basket": Object.entries(
        subscription.user?.subscriptionBasket || {}
      )
        .map(
          ([planName, products]) =>
            `${planName}: ${products
              .map((product) => product.productName)
              .join(", ")}`
        )
        .join("; "),
      "Created Time": subscription.items
        .map((item) => formatDate(item.created))
        .join(", "),
      "Current Period Start": formatDate(subscription.currentPeriodStart),
      "Current Period End": formatDate(subscription.currentPeriodEnd),
    }));

    // Sort the data by current period end
    const sortedData = data.sort(
      (a, b) =>
        new Date(a["Current Period End"]) - new Date(b["Current Period End"])
    );

    const worksheet = XLSX.utils.json_to_sheet(sortedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Subscriptions");
    XLSX.writeFile(workbook, "subscriptions.xlsx");
  };

  // Sort the subscriptions by current period end
  const sortedSubscriptions = [...subscriptions].sort(
    (a, b) => a.currentPeriodEnd - b.currentPeriodEnd
  );

  return (
    <div className="bg-gray-900">
      <div className="mx-auto max-w-7xl">
        <div className="bg-gray-900 py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-base font-semibold leading-6 text-white">
                  Subscriptions
                </h1>
                <p className="mt-2 text-sm text-gray-300">
                  A list of all the subscriptions including customer details and
                  subscription baskets.
                </p>
              </div>
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button
                  type="button"
                  className="block rounded-md bg-indigo-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  onClick={downloadAsExcel}
                >
                  Download as Excel
                </button>
              </div>
            </div>
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  {loading ? (
                    <p className="text-sm text-gray-300">
                      Loading subscriptions...
                    </p>
                  ) : error ? (
                    <p className="text-sm text-red-500">{error}</p>
                  ) : (
                    <table className="min-w-full divide-y divide-gray-700">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                          >
                            Customer Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                          >
                            Customer Email
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                          >
                            Shipping
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                          >
                            Subscription Basket
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                          >
                            Created Time
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                          >
                            Current Period Start
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                          >
                            Current Period End
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-800">
                        {sortedSubscriptions.map((subscription) => (
                          <tr key={subscription.id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                              {subscription.customer.name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              {subscription.customer.email}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              {subscription.customer.shipping && (
                                <div>
                                  <p>{subscription.customer.shipping.name}</p>
                                  <p>
                                    {
                                      subscription.customer.shipping.address
                                        .line1
                                    }
                                  </p>
                                  <p>
                                    {
                                      subscription.customer.shipping.address
                                        .city
                                    }
                                    ,{" "}
                                    {
                                      subscription.customer.shipping.address
                                        .state
                                    }{" "}
                                    {
                                      subscription.customer.shipping.address
                                        .postal_code
                                    }
                                  </p>
                                  <p>
                                    {
                                      subscription.customer.shipping.address
                                        .country
                                    }
                                  </p>
                                </div>
                              )}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              {subscription.status}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              {subscription.user &&
                                subscription.user.subscriptionBasket && (
                                  <div>
                                    {Object.entries(
                                      subscription.user.subscriptionBasket
                                    ).map(([planName, products]) => (
                                      <div key={planName}>
                                        <h5 className="font-semibold text-white">
                                          {planName}
                                        </h5>
                                        <ul className="ml-4 list-disc">
                                          {products.map((product) => (
                                            <li
                                              key={product._id}
                                              className="text-gray-300"
                                            >
                                              {product.productName}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    ))}
                                  </div>
                                )}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              <ul>
                                {subscription.items.map((item) => (
                                  <li key={item.id}>
                                    {formatDate(item.created)}
                                  </li>
                                ))}
                              </ul>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              {formatDate(subscription.currentPeriodStart)}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              {formatDate(subscription.currentPeriodEnd)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionsList;
