import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LoginForm from "./LoginForm";
import Welcome from "./Welcome";
import PrivateRoute from "./PrivateRoute";

const App = () => {
  const token = localStorage.getItem("token");

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/login"
            element={token ? <Navigate replace to="/welcome" /> : <LoginForm />}
          />
          <Route
            path="/welcome"
            element={
              <PrivateRoute>
                <Welcome />
              </PrivateRoute>
            }
          />
          <Route
            path="/"
            element={<Navigate replace to={token ? "/welcome" : "/login"} />}
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
