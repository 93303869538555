import React, { useState } from "react";
import axios from "axios";
import Notification from "./Notification"; // Adjust the import path as necessary

const EventForm = () => {
  // State for form fields
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [date, setDate] = useState("");
  const [organizer, setOrganizer] = useState("");
  const [website, setWebsite] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [error, setError] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");

  // Form submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    // Construct the event data
    const eventData = {
      name,
      description,
      location,
      startTime,
      endTime,
      date,
      organizer,
      website,
      imgUrl,
    };

    try {
      // Retrieve the token from local storage
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Authentication token not found.");
        return;
      }

      // Make the POST request to the API endpoint
      const response = await axios.post(
        "https://mosaic.web3talk.xyz/admin/event",
        eventData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Set notification for success response
      setNotificationTitle("Event Created");
      setNotificationMessage("Your event has been successfully created.");
      setShowNotification(true);

      // Clear the form fields
      setName("");
      setDescription("");
      setLocation("");
      setStartTime("");
      setEndTime("");
      setDate("");
      setOrganizer("");
      setWebsite("");
      setImgUrl("");
      // ... clear other fields
    } catch (err) {
      // Set notification for error response
      const errorMessage =
        err.response?.data?.error || err.message || "Failed to create event.";
      setNotificationTitle("Error Creating Event");
      setNotificationMessage(errorMessage);
      setShowNotification(true);

      // Log error to console and set error message
      console.error("Error creating event:", err);
      setError(errorMessage);
    }
  };

  return (
    <div className="max-w-md mx-auto text-white">
      <h2 className="text-lg font-semibold">Create New Event</h2>
      <form onSubmit={handleSubmit} className="mt-4 space-y-4">
        {/* Name field */}
        <div>
          <label htmlFor="name" className="block text-sm font-medium">
            Name
          </label>
          <input
            id="name"
            type="text"
            required
            className="mt-1 block w-full rounded-md bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        {/* Description field */}
        <div>
          <label htmlFor="description" className="block text-sm font-medium">
            Description
          </label>
          <textarea
            id="description"
            required
            className="mt-1 block w-full rounded-md bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        {/* Location field */}
        <div>
          <label htmlFor="location" className="block text-sm font-medium">
            Location
          </label>
          <input
            id="location"
            type="text"
            required
            className="mt-1 block w-full rounded-md bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>

        {/* Start Time field */}
        <div>
          <label htmlFor="startTime" className="block text-sm font-medium">
            Start Time
          </label>
          <input
            id="startTime"
            type="datetime-local"
            required
            className="mt-1 block w-full rounded-md bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
          />
        </div>

        {/* End Time field */}
        <div>
          <label htmlFor="endTime" className="block text-sm font-medium">
            End Time
          </label>
          <input
            id="endTime"
            type="datetime-local"
            required
            className="mt-1 block w-full rounded-md bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
          />
        </div>

        {/* Date field */}
        <div>
          <label htmlFor="date" className="block text-sm font-medium">
            Date
          </label>
          <input
            id="date"
            type="date"
            required
            className="mt-1 block w-full rounded-md bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>

        {/* Organizer field */}
        <div>
          <label htmlFor="organizer" className="block text-sm font-medium">
            Organizer
          </label>
          <input
            id="organizer"
            type="text"
            required
            className="mt-1 block w-full rounded-md bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={organizer}
            onChange={(e) => setOrganizer(e.target.value)}
          />
        </div>

        {/* Website field */}
        <div>
          <label htmlFor="website" className="block text-sm font-medium">
            Website
          </label>
          <input
            id="website"
            type="url"
            required
            className="mt-1 block w-full rounded-md bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="imgUrl" className="block text-sm font-medium">
            Image Url
          </label>
          <input
            id="imgUrl"
            type="url"
            required
            className="mt-1 block w-full rounded-md bg-gray-800 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={imgUrl}
            onChange={(e) => setImgUrl(e.target.value)}
          />
        </div>

        {/* Error message */}
        {error && <p className="mt-2 text-sm text-red-500">{error}</p>}

        {/* Submit button */}
        <div>
          <button
            type="submit"
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Create Event
          </button>
        </div>
      </form>
      {showNotification && (
        <Notification
          show={!!showNotification} // The double-bang (!!) ensures the value is cast to a boolean
          setShow={setShowNotification}
          title={notificationTitle}
          message={notificationMessage}
        />
      )}
    </div>
  );
};

export default EventForm;
